.wrapper {
  grid-template-columns: auto;
  grid-template-rows: 30% auto;
}

@screen ipad {
  .wrapper {
    grid-template-columns: 30% auto;
    grid-template-rows: auto;
  }
}

@screen desktop {
  .wrapper {
    grid-template-columns: 30% auto;
    grid-template-rows: auto;
  }
}
